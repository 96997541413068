.menu-item {
    font-family: 'Roboto';
    font-style: normal;
    color: #3D3936;
 }

 .menu-item:hover {
    background: #007BE5 !important;
    color: #FFFFFF;
 }

 .menu-divider {
    width: 90%;
    margin-left: 5% !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
 }

 #user-menu-button:hover {
   background-color: transparent;
 }

 .header-icon{
    color: rgb(97, 97, 97);
    margin-top: -4px;
    margin-right: 24px;
 }
