.main-layout-container {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.sidebar { 
    height: calc(100vh - 76px);
    border-right: 1px solid gray;
    font-weight: bold;
    font-size: 14pt;
    background-color: #798898;
    color: white;
}

.main-layout-content {
    height: calc(100vh - 100px);
    overflow: auto;
}