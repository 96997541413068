.navbar-container {
    border-bottom: 1px solid gray;
    margin: 0px;
}

.whatsapp-color {
    color: #05A884;
}

.navBar-item {
    cursor: pointer;
}
