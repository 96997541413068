.user-profile form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .error-message {
    color: rgba(255, 0, 0, 0.6);
    margin-right: 20px;
    margin-bottom: 5px;
  }
  
  .error-message::before {
    content: "⚠ ";
  }

  .user-profile-separator {
    height: 10px;
    width: 1000px;
  }
  
  .content-row-width {
    width: 80%;
  }