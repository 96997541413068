html,
body {
  margin: 0px;
  padding: 0px;
}

.k-background-yellow {
  background-color: #FFCF5C;
}

.k-text-yellow {
  color: #FFCF5C;
}

.k-background-blue {
  background-color: #31B5E1 !important;
}

.k-text-blue {
  color: #31B5E1;
}

.k-background-pink {
  background-color: #F8BCA2;
}

.k-text-pink {
  color: #F8BCA2;
}